import * as React from "react"
import { Link } from "gatsby"
import { useState } from "react"
import { PlayOutline, StarOutline, InformationCircleOutline } from 'react-ionicons'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import EndCheckout from '../../components/EndCheckout.js'
import Footer from '../../components/Footer.js'
import Logo from '../../components/Logo.js'
import { CheckoutContext } from "../../context/CheckoutContext"
import ReactPlayer from 'react-player'



const Stem = (props) => {
    return <article class="box">

        <div className="columns is-mobile content">
            <div className="column is-narrow">
                <p className="image is-96x96 stem-image">
                    <img src={props.image} alt={props.title} class="is-rounded" />
                </p>
            </div>
            <div className="column">
                <p className="title is-5">{props.title}</p>
                <p class="subtitle is-6">{props.native}</p>
                <p>{props.description}</p>
            </div>
        </div>




        {/* <hr /><div className="content">



                    <p class="title is-6">Aprūpe</p>
                    <p>{props.care}</p>

        </div> */}



    </article>
}

const Video = ({ url, captions }) => {
    return (<ReactPlayer
        url={url}
        width='100%'
        height='100%'
        // muted={true}
        config={{
            file: {
                attributes: {
                    controls: true,
                },
            },
            tracks: [
                { kind: 'captions', src: captions, label: "Instrukcijas", srcLang: 'lv', default: true }
            ]
        }}

    />)
}


// markup
const Hi = () => {

    const flowers = [


        {
            title: "Puķuzirņi",
            image: "https://i.imgur.com/dk2pHspb.jpg",
            native: "Vidusjūras apgabals",
            description: "Debešķīgi skaisti, bet vāzes nav ilgs dzīves ilgums aptuveni 4-5 dienas. Izrādās puķuzirņi radušies saulainajā Sicīlijā. Pasaulē ir pierasts dāvināt puķuzirņus, kā pateicības vai atzinības zīmi. Interesanti, ka Francijā līgavām, puķuzirņi simbolizē veiksmi, izturību un reizē arī atvadas",
        },    

        {
            title: "Peonija",
            image: "https://i.imgur.com/BcCJXAQb.jpg",
            native: "Ziemeļamerika",
            description: "Tās ir neatņemama sastāvdaļa ikvienam vecmāmiņas dārzam, bet tagad tās ir palikušas par vienām no pieprasītākajām vasaras puķēm.",
        },
        {
            title: "Limonija",
            image: "https://i.imgur.com/n4GqPoJb.jpg",
            native: "Centrālie reģioni",
            description: "Kaut arī citi to nosaukumi min lavandas, tās nebūt nav radniecīgas. Latvijā tās pazīst arī par sausziedēm, jo tās ir viegli izkaltēt un saglabāt sausas.",
        },
        {
            title: "Rīsupuķes",
            image: "https://i.imgur.com/Oe6WIzRb.jpg",
            native: "Austrālija",
            description: "Šie karstumu mīlošie krūmi, sniedz smalkus ziedus, kas, ja īpaši piedomā, nedaudz smaržo pēc karija garšvielām.",
        },
        {
            title: "Antūrijas",
            image: "https://i.imgur.com/xDbOjH8b.jpg",
            native: "ASV",
            description: "Garas un tropiskas puķes, kuru ziedi atrodami daudzās nokrāsās. Tās izplatītas arī kā stādaugi, bet ziedu kompozīcijās tās piešķir eksotiskuma pieskaņu.",
        },
        {
            title: "Kukurūzas lillija",
            image: "https://i.imgur.com/hSxBUbHb.jpg",
            native: "Dienvidāfrika",
            description: "Izplatīts Eiropas dārzos, tie ir unikāli un reti izmantoti ziedi, kas piesaista ar savu smalko, bet izteikto izskatu.",
        },
        {
            title: "Ievu koka ziedi",
            image: "https://i.imgur.com/zyQYf9bb.jpg",
            native: "Eiropa un Āzija",
            description: "Pavasarī bieži jūtama šo ziedu smarža, bieži tā ir reibinoši spēcīga, bet ienest kaut vienu zaru mājā, tā ienes arī pavasara sajūtu.",
        },
        
        {
            title: "Roze",
            image: "https://i.imgur.com/v35ns2pb.jpg",
            native: "Āzija",
            description: "Rozes ir iesakņojušās mūsu pasaulē ar lielo popularitāti un dažādajām nozīmēm. Vai zināji, ka pasaulē ir vairāk par 25 000 rožu šķirņu?",
        },
        {
            title: "Pistāciju lapas",
            image: "https://i.imgur.com/3oYQgBmb.jpg",
            native: "Centrālāzija un Tuvie Austrumi",
            description: "Pistāciju koku ģints ne tikai dod gardos riekstus, bet arī citus produktus, tostarp skaistus dekoratīvus zarus, kuru lapas lieliski papildina ziedu kompozīcijas.",
        },
        {
            title: "Citronkoka lapas",
            image: "https://i.imgur.com/F9Ix1eLb.jpg",
            native: "Dienvidāfrika",
            description: "Arī zināmas kā salal lapas, tās ir izturīgas un lieliski piešķir krāsu un apjomu ziedu kompozīcijām, tāpēc tās plaši izmanto floristi.",
        },

    ]
    return (
        <main class="">


            <Helmet title="Instrukcija un atsauksmes" defer={false} />

            <section class="section">

                <div class="columns is-mobile">
                    <div class="column pt-0">
                        <Logo />
                    </div>
                    <div class="column is-one-third pt-0">
                        <EndCheckout />
                    </div>
                </div>
                <hr />


                <div className="container content">


                    <h1 class="title is-3 has-text-centered">👋 Tavi ziedi ir klāt!</h1>


                    {/* <p className="title is-3">Ko darīt tālāk?</p> */}

                    <div class="columns  has-text-centered">
                        <div class="column">
                            <PlayOutline height="22px" width="22px" />
                            <p>Noskaties īso instrukciju video</p>
                        </div>
                        <div class="column">
                            <InformationCircleOutline height="22px" width="22px" />
                            <p>Iepazīsties ar padomiem, lai paildzinātu prieku par ziediem</p>
                        </div>
                        <div class="column">
                            <StarOutline height="22px" width="22px" />
                            <p>Novērtē saņemtos ziedus un padalies ar draugiem</p>
                            <p>
                                <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="button is-outlined">Aizpildīt novērtējumu</a>

                            </p>
                        </div>
                    </div>

                </div>
            </section>

            <section className="section" style={{ backgroundColor: '#4a4a4a' }}>
                <div className="container content">


                    <Video
                        url="https://vz-da54803c-c33.b-cdn.net/47a49476-3fc9-4bde-83e1-8f844d91a744/playlist.m3u8"
                        captions="/titles/013.vtt"
                    />
                </div>
            </section>


            <section className="section">
                <div className="container content">

                    <div className="columns">
                        <div className="column">


                            <h2 class="title is-3">Eksotiskā pļava</h2>

                            <p>Mēs turamies pie filozofijas, ka ziedu kompozīcijai ir jābūt, ne tikai skaistai, bet jūs arī iemācaties jaunus paņēmienus ziedu kārtošanā un kopšanā.</p>

                            <p>Tāpēc šoreiz kompozīcija prasīs mazliet vairāk pacietības un būs nedaudz grūtāk konstruējama. Droši seko līdzi mūsu ieteiktajai kārtībai, vai arī improvizē pati, bet paturi galvā pāris galvenās lietas:</p>

                            <ul>
                            <li>Kompozīcija tiek veidota, lai skatītos no priekšas (tas tādēļ, ka baltās vai zaļās antūrijas ir visskaistākās un interesantākās no vienas puses).</li>
                            <li>Lai vāzē visi ziedi nesagāztos vienā čupā, sāc ar zaļumiem. Ņemām garāko zaru čupiņu un izkārtojam pa vāzes aizmuguri un sāniem. Pārējo vari improvizēt.</li>
                            <li>SVARĪGI: Neliekam kātus taisni iekšā vāzē. Zaļumus un ziedus kartojam pēc diognālās metodes, tas ir - liekot ziedu/zaļumu vāzes kreisajā pusē, kāts vāzē ūdenī būs labajā. Varbūt vieglāk iedomāties tos pašus 45 grādus, tā arī liekam puķes vāzē. Kāpēc tā darām? Liekot pa diognāli ziedus un zarus - tie vāzē ūdenī izveidos tādu kā “ligzdu vai tīklu”, kas atbalstīs citus kātus un dēļ šiem atbalstiem iespējams vāzē izkārtot šādas lielas kompozīcijas un ziedi stāvēs izvēlētajās vietās.</li>
                            <li>Triks numur 2: ja kāds zieds nenofiksējas vietā, kur vēlies, tad izmanto zaļumus kā papildus atbalstu, pret kuru vari “atstutēt” ziedu.</li>
                            <li>Piemēro balto vai zaļo Antūriju kātu garumu savai kompozīcijai, nogriežot tos slīpi 45 grādu lenķī.</li>
                            <li>Esam iekļāvuši pušķī arī smaržigos Ievas ziedus, bet ja to smarža nepatīk, tad neizmanto to kompozīcijā. Kā arī Ievas ziedi vāze parasti stāv 2-3 dienas, kad tie noziedējuši, izņem to ārā no kompozīcijas un nomaini vāzē ūdeni.</li>
                            <li>Parasti trauslākos ziedus, kā piemēram, puķuzirņus atstājam uz pašām kompozīcijas beigām, lai konstruējot kompozīciju netiek traumēti ziedi.</li>
                            </ul>

                            <p>Pirms sāc:</p>

                            <ul>
                                <li>Sakārto zaļumus (pistāciju zarus un salal lapas) 3 čupās  - garie, vidējie un īsie zaļumi, tas palīdzēs likšanas procesā vieglāk atrast nepieciešamo zaļumu.</li>
                                <li>Ziedi transportēšanas dēļ var izskatīties noliekušies. Tas nekas - atjauno griezuma vietu un ieliec tos vāzē ar ūdeni. Pēc dažām stundām tie atgūs savu formu.</li>
                                <li>Ieber ziedu barību vāzē pirms kompozīcijas sākšanas, atceries 1 pulverītis uz 0,5l ūdens.</li>
                            </ul>
                                

                            <p>Ķeramies pie darba! Seko video, lai redzētu darba procesu. </p>

                            <p>Kā patīk šīs nedēļas kompozīcija? Atsūti foto Instagram! Lūdzu sniedz anonīmu novērtējumu par saņemtajiem ziediem un piegādi - <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="">Aizpildīt anketu</a></p>


                        </div>
                        <div className="column">

                            <img src="https://i.imgur.com/vXlYKf1h.jpg" alt="Nedēļas pušķis"/>

                            <h2 class="title is-34">Aprūpe</h2>

                            <p>Seko šīm norādēm, lai ziedi saglabātos ilgāk.</p>

                            <ol>
                                <li>Atceries ūdenī vāzē mainīt katru otro dienu</li>
                                <li>Izņem ziedus un noskalo ziedu kātus</li>
                                <li>Izlej ūdenu un izmazgā vāzi tīru</li>
                                <li>Iepildi istabas temperatūras ūdeni, tajā izšķīdinot ziedu barību</li>
                                <li>Ziediem nogriez 3cm no stublāju apakšas, ar asām šķērēm griežot slīpi, 45° lenķī</li>
                            </ol>

                            <h3 class="title is-4">Par ziedu barību</h3>

                            <p>Ziedu barībai ir svarīgi ievērot norādīto barības-ūdens attiecību, lai tā nav par stipru vai vāju. Ja tā būs par koncentrētu, tad ziedi var apdegt, ja koncentrācija par vāju, tad ziediem pietrūkt vajadzīgās barības vielas un tie ātrāk novecos. Mūsu līdzi dotā barības vielas paciņa, tiek šķīdināta 0,5l ūdens</p>

                        </div>
                    </div>




                </div>
            </section>




            <section className="section" style={{ backgroundColor: '#F0E2D7' }}>
                <div className="container content">

                    <p className="title is-3">Kādus ziedus saņēmi?</p>

                    <div className="columns is-multiline">
                        {flowers.map(f => {
                            return (
                                <div className="column is-one-third">
                                    <Stem
                                        title={f.title}
                                        latin={f.latin}
                                        image={f.image}
                                        native={f.native}
                                        care={f.care}
                                        description={f.description}
                                    />

                                </div>
                            )
                        })}


                    </div>


                </div>
            </section>


            <section className="section content">
                <div className="container">

                    <div className="columns">
                        <div className="column">

                            <h2 class="title is-3">Novērtējums</h2>

                            <p>Mēs priecāsimies, ja atstāsi godīgu novērtējumu par Heyday pakalpojumu.</p>
                            <p>Tas aizņems tikai 2 minūtes.</p>

                            <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="button is-primary">Aizpildīt anketu</a>


                        </div>
                        <div className="column">

                            <h2 class="title is-3">Pasaki par mums saviem draugiem</h2>

                            <p>Ja Tev saņemtie ziedi patika, padalies ar Heyday saviem draugiem. Jo vairāk cilvēki pievienojas mūsu lokam, jo plašāk varēsim sniegt ziedu prieku.</p>

                            <p>
                                <a class="button is-success" href="whatsapp://send?text=Heyday ziedu abonements https://heyday.lv?utm_source%3Dshare%26utm_medium%3Dwhatsapp%26utm_campaign%3Dfromhi" data-action="share/whatsapp/share">Pārsūtīt saiti Whatsapp</a>
                            </p>


                        </div>
                    </div>





                </div>


            </section>

            <Footer />



        </main>
    )
}

export default Hi
